.application-detail .appliaction-header {
    background-color: #FFF;
    position: sticky;
    top: 68px;
    left: 0;
    z-index: 10;
    border-bottom: 1px solid #EAEDF0;
    box-shadow: 0px 24px 0px #f5f4f8;
    transition: 0.5s;
}

.application-wrapper {
    min-height: calc(100vh - 155px);
}

@media (max-width: 575px) {
    .application-detail .appliaction-header {
        box-shadow: 0px 10px 0px #f5f4f8;
    }
}

.application-detail .appliaction-header .lm-breadcrumb {
    background: #fff;
    z-index: 10;
    position: relative;
}

#dynamic_sticky_form {
    height: max-content;
    transition: transform 0.5s ease-in-out;
    background-color: #FFF;
}

.hide-sticky-form {
    position: fixed;
    transform: translateY(-100%);
    visibility: hidden;
}


.application-tabs {
    border-bottom: unset;
}

.lm-nav-tabs.application-tabs {
    position: relative;
}

#slideLeft,
#slideRight {
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
}

#slideLeft {
    left: 0;
}

#slideRight {
    right: 0;
}

.hide-btn {
    display: none;
}

.application-detail .lm-button2 img,
.application-detail .lm-button2 svg {
    margin-top: -2px;
    margin-right: 5px;
}

.application-detail .appliaction-no {
    color: #22315D;
    font-size: 24px;
    font-weight: 800;
    margin-right: 15px;
}

.application-detail .header-btn-sec {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
}

.application-detail .header-btns {
    gap: 14px;
}

.application-detail .form-accordian-button {
    position: relative;
    padding: 20px 0 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #EAEDF0;
    font-size: 14px;
    font-weight: 500;
}

.application-detail .form-accordian-button::after {
    content: ' ';
    height: 18px;
    width: 18px;
    background-image: url('../../assets/img/angle_down.svg');
    position: absolute;
    right: 10px;
    top: 25px;
    transition: 0.3s;
}

.application-detail .open-basic-form-accordion::after {
    transform: rotate(180deg);
    transition: 0.3s;
}

.application-detail .basic-info-form {
    padding: 10px 0 20px;
}

.application-detail .basic-info-form {
    overflow: hidden;
    height: 0;
    transition: height 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
}

.application-detail .basic-info-form.open {
    height: max-content;
    /* Adjust this value to fit the content size */
    transition: height 0.5s ease-in, opacity 0.5s ease-in;
    opacity: 1;
}

.application-detail .basic-info-form.closed {
    height: 0;
    transition: height 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
}

.application-detail .tab-content .lm-grid-table {
    margin: 0;
}

#affiliates .drawer-footer {
    box-shadow: 0px -8px 16px 0px #0000000F;
}

.application-detail .sub-total-label,
.sub-total-amount {
    font-size: 13px;
    font-weight: 500;
}

.application-detail .total-label {
    font-size: 16px;
    font-weight: 500;
}

.application-detail .total-amount {
    font-size: 18px;
    font-weight: 700;
    color: #22315D;
}

@media (max-width:575px) {
    .application-detail .basic-info-form.open {
        height: calc(100vh - 380px);
        overflow-y: auto;
    }
}

/* amortization css */
.amortization-schedule-table td {
    padding: 14px 16px;
}

.amortization-schedule-table th {
    padding: 20px 16px;
}

/* PFS Screen css */
.pfs-card {
    overflow: hidden;
}

.pfs-card .pfs-data-loop-row {
    border-top: 1px solid #EAEDF0;
}

.pfs-card .pfs-data-loop-row:last-child {
    border-bottom: 1px solid #EAEDF0;
}

.pfs-card .bb-1 {
    border-bottom: 1px solid #EAEDF0;
}

.pfs-card .pfs-card-head {
    padding: 20px;
    background-color: #F7F7F7;
    gap: 10px;
}

.pfs-card-label {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #99A4B1;
    letter-spacing: 1px;
}

.pfs-card-value {
    font-size: 13px;
    font-weight: 600;
    color: #0F1828;
}

.status.current,
.current .status {
    background: #DAF4E8;
    color: #12B76A;
    font-size: 12px;
    font-weight: 600;
}

.delinquent .status,
.status.delinquent {
    background: #FFE4E4;
    color: #FD151B;
    font-size: 12px;
    font-weight: 600;
}

/* verification screen css */
.verification-wrap .table-head {
    background: #F7F7F7;
    border-radius: 5px 5px 0 0;
}

.verification-label {
    font-size: 12px;
}

.verification-wrap .unverified {
    font-size: 13px;
    font-weight: 500;
    color: #FD151B;
}

.verification-wrap .verified {
    font-size: 13px;
    font-weight: 500;
    color: #12B76A;
}

.verification-wrap .unverified img,
.verification-wrap .verified img {
    margin-right: 5px;
    margin-top: -3px;
}

.verification-wrap th {
    padding: 12px 20px;
}

.verification-wrap td {
    padding: 12px 20px;
}

.verification-wrap table {
    width: 100%;
    border-collapse: collapse;
}

.verification-wrap .left {
    text-align: left;
    width: 35%;
}

.verification-wrap .center {
    text-align: left;
    /* Keep text left-aligned but center the column */
    width: 35%;
}

.verification-wrap .right {
    text-align: right;
    width: 30%;
}

/* Notes page css */
.notes-page-head {
    font-size: 18px;
    font-weight: 700;
    color: #07142D;
}

.note-card {
    border: 1px solid #EAEDF0;
    padding: 16px 24px;
    border-radius: 6px;
    background-color: #FFFFFF;
    margin-bottom: 20px;
}

.note-reply-card {
    border: 1px solid #EAEDF0;
    padding: 16px 24px;
    border-radius: 6px;
    background-color: #F7F7F7;
    margin: 20px 0;
}

.note-reply-sec {
    margin-left: 50px;
}

.note-card .user-image .profile-img img,
.note-form .user-image .profile-img img {
    height: 44px;
    width: 44px;
    border-radius: 50%;
}

.note-card .user-image .profile-text,
.note-form .user-image .profile-text {
    height: 44px;
    width: 44px;
    background-color: #EAEDF0;
    text-transform: uppercase;
    border-radius: 44px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 12px;
}

.note-card .user-name,
.note-form .user-name {
    font-size: 13px;
    font-weight: 600;
    color: #07142D;
}

.note-form .user-name {
    font-size: 16px;
}

.note-card .note-timing,
.note-form .note-timing {
    font-size: 12px;
    font-weight: 500;
    color: #99A4B1;
}

.note-form .user-dtls {
    background: #F7F7F7;
    border: 1px solid #EAEDF0;
    border-radius: 6px;
    padding: 12px 16px;
}

.note-card-heading {
    display: flex;
    justify-content: space-between;
    place-items: center;
    margin-bottom: 16px;
}

.note-card .status {
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    width: max-content;
}

.note-card .new {
    background: #DAF4E8;
    color: #12B76A;
}

.note-card .in-progress {
    background: #FFE9DC;
    color: #EE7930;
}

.note-card .review-required {
    background: #F0EAFF;
    color: #6941C6;
}

.note-card-body p {
    font-size: 13px;
    color: #07142D;
    font-weight: 500;
}

.note-card .replies-count {
    font-size: 13px;
    color: #99A4B1;
    font-weight: 500;
}

.note-card .replies-count img {
    margin-right: 6px;
}

.note-card .reply-btn {
    font-size: 13px;
    font-weight: 500;
    color: #4057E3;
    text-decoration: none;
    cursor: pointer;
}

.note-trash:hover {
    filter: brightness(0.8);
    transition: 0.5s;
}

.trash-modal-footer {
    display: flex;
    gap: 15px;
    flex-wrap: nowrap;
    padding: 16px;
}

.trash-modal-footer button {
    width: 100%;
}

.note-reply-sec .user-image .profile-img img,
.reply-txt-box .user-image .profile-img img {
    height: 36px;
    width: 36px;
}

.note-reply-sec .user-image .profile-text,
.reply-txt-box .user-image .profile-text {
    height: 36px;
    width: 36px;
    padding: 7px 0px;
    text-align: center;
}

/* cashflow screen css */

.cashflow-wrap input[type=checkbox] {
    margin-left: 0;
}

.cashflow-wrap .lm-grid-table td {
    padding: 16px;
}

.cashflow-wrap .lm-grid-table th {
    padding: 20px 16px;
}

.oc-epc-tag {
    border: 1px solid #EAEDF0;
    padding: 4px;
    border-radius: 6px;
    width: max-content;
    gap: 6px;
}

.epc-tag,
.oc-tag {
    padding: 1px 9px;
    border-radius: 4px;
    color: #99A4B1;
    font-size: 14px;
    font-weight: 500;
    /* cursor: pointer; */
}

.EPC .epc-tag,
.OC .oc-tag {
    background: #4057E3;
    color: #FFFFFF;
}

.table-sheet .bg-grey-thead {
    background-color: #F7F7F7;
}

.table-sheet th {
    padding: 16px 15px;
}

.table-sheet td {
    border-bottom: unset;
    padding: 10px 15px;
}

.table-sheet .form-group {
    margin-bottom: 0;
}

.table-sheet .form-group label {
    display: none;
}

.table-sheet tbody tr:first-child td {
    padding: 25px 15px 10px;
}

.table-sheet tbody tr:last-child td {
    padding: 10px 15px 25px;
}

.table-sheet .grid-dropdown {
    display: flex;
    place-content: center;
    place-items: center;
}

.table-sheet .grid-dropdown select {
    width: max-content;
}

.table-sheet .current-year {
    margin-left: auto;
    width: max-content;
    margin-right: auto;
}

.table-sheet .input-dollar::after {
    top: 8px;
}

.table-sheet .cf-cust-input-dollar::after {
    top: 8px;
}

.table-sheet-parent-row td {
    border-top: 1px solid #EAEDF0;
}

.table-sheet-child-row td {
    border-bottom: 1px solid #EAEDF0;
}

.table-sheet-child-row span {
    font-size: 12px;
    font-weight: 500;
    color: #97A3B2;
    border-left: 1px solid #D1D5DC;
    padding-left: 10px;
}

.table-sheet-child-row td {
    padding: 0px 15px 10px;
}

.table-sheet-child-row .form-group input {
    padding: 5px 12px;
}

.table-sheet .table-sheet-child-row .input-dollar::after {
    top: 6px;
}

.table-month-picker .select__single-value {
    font-size: 12px !important;
}

.table-month-picker .select__dropdown-indicator {
    padding: 5px;
}

.table-sheet .showErrorBorderYear {
    color: #FD151B;
    border: 1px solid #FD151B;
    border-radius: 8px;
    padding: 4px 8px
}

.dashboard-wrap .table-month-picker .select__control {
    min-height: 22px;
    padding: 0 0 0 6px;
    margin-left: 6px;
}

.dashboard-wrap .table-month-picker .select__menu {
    min-width: 76px;
}

.fst-col-25 tr td:first-child,
.fst-col-25 tr th:first-child {
    width: 20%;
}

.fst-col-15 tr td:first-child,
.fst-col-15 tr th:first-child {
    width: 15%;
}

.border-none {
    border: unset;
}

.cf-value-card {
    background: #F7F7F7;
    border: 1px solid #EAEDF0;
    padding: 12px 16px 10px 16px;
    margin-bottom: 12px;
    border-radius: 4px;
}

.cf-value-card .title {
    font-size: 12px;
    font-weight: 600;
    color: #07142D;
    text-transform: uppercase;
}

.cf-value-card .value {
    font-size: 18px;
    font-weight: 700;
    color: #07142D;
}

.selected-row {
    background-color: #F7F7F7;
}

/* Document page css */

.lm-button1.dropdown-toggle.btn-primary:focus {
    box-shadow: none;
}

.lm-button1.dropdown-toggle.btn-primary::after {
    display: none;
}

.lm-grid-table .document_table_heading {
    background-color: #F7F7F7;
}

.documet-wrap .lm-grid-table .document_table_heading td {
    padding: 8px 20px;
}

.lm-grid-table .document_table_heading:hover {
    box-shadow: none;
}

.lm-grid-table .document_table_heading .basic-single .select__control {
    border: unset;
    background-color: transparent;
    padding-left: 0;
}


.lm-grid-table .document_table_heading .basic-single .select__single-value {
    font-size: 16px;
    font-weight: 600;
    color: #07142D;
}

.lm-grid-table .document_table_heading .select__option {
    font-size: 16px;
    font-weight: 600;
    color: #07142D;
    border-radius: 6px;
}

.lm-grid-table .document_table_heading .select__placeholder {
    font-size: 16px;
    font-weight: 600;
    color: #07142D;
}

.lm-grid-table .document_table_heading .basic-single .select__control:hover {
    border: unset;
    box-shadow: none;
}

.lm-grid-table .document_table_heading .select__indicator svg path {
    fill: #07142D;
}

.lm-grid-table .document_table_heading .basic-single .select__control--is-focused {
    border: none;
    box-shadow: none;
}

.documet-wrap .grid-head {
    padding: 16px 24px;
}

.documet-wrap .filter-search-group .select__control {
    width: 260px;
}

.documet-wrap .filter-search-group .lm-search-bar input {
    width: 336px;
}

.w-max-content {
    width: max-content;
}

.documet-wrap .lm-grid-table tr th {
    padding: 20px 20px;
}

.documet-wrap .lm-grid-table tr td {
    padding: 12px 20px;
}

.documet-wrap .lm-grid-table tr th:first-child {
    width: 40px;
}

.documet-wrap .lm-grid-table tr td:first-child {
    width: 40px;
}

.documet-wrap .grid-link,
.version-history-table-row .grid-link {
    color: #4057E3;
    font-size: 13px;
    font-weight: 500;
    text-decoration: underline;
}

.doc-date {
    font-weight: 500;
}

.doc-status {
    font-size: 13px;
    font-weight: 500;
}

.documet-wrap .lm-grid-table .Sent_for_signature .doc-status {
    color: #FD151B;
}

.documet-wrap .lm-grid-table .Completed .doc-status {
    color: #12B76A;
}

.documet-wrap .lm-grid-table .Uploaded .doc-status {
    color: #055FFC;
}

.documet-wrap .lm-grid-table .To_sign .doc-status {
    color: #EE7930;
}

.documet-wrap .lm-grid-table .To_upload .doc-status {
    color: #6941C6;
}

.documet-wrap .doc_app_options {
    padding: 8px 6px;
}

.upload-doc-popup .uploaded-file-box .close {
    position: relative;
    top: -1px;
}

.upload-doc-popup .uploaded-file-box .close:hover img {
    transition: 0.3s;
    filter: brightness(0);
}

.doc-upload-percentage {
    color: #07142D;
    font-size: 13px;
    font-weight: 700;
}

.view-doc-button {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.view-doc-button img {
    opacity: 0.3;
    width: 16px;
    transition: 0.3s;
}

.view-doc-button:hover img {
    opacity: 1;
    transition: 0.3s;
}

.generate-report-modal .report-doc-box {
    display: flex;
    margin-bottom: 15px;
    place-items: center;
}

.generate-report-modal .report-doc-name {
    width: 100%;
    place-items: center;
    margin-right: 10px;
}

.generate-report-modal .report-doc-box img {
    margin-right: 8px;
}

.generate-report-modal .report-doc-box .lm-button1 {
    padding: 4px 16px;
}

.generate-report-modal .report-doc-box p {
    color: #07142D;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}

.generate-report-modal .report-doc-box div {
    font-size: 12px;
    font-weight: 500;
}

.generate-report-modal .report-doc-box .disabled-btn {
    background: #F3F4F6;
    color: #99A4B1;
    pointer-events: none;
}

.generate-report-modal .drawer-body {
    padding: 72px 16px 12px;
    overflow: auto;
}

.generate-report-bottom-overlay {
    position: absolute;
    width: 100%;
    height: 44px;
    bottom: 0;
    background: linear-gradient(360deg, #FFFFFF 28.01%, rgba(255, 255, 255, 0) 93.18%);
}

.version-history-modal .drawer-title span {
    font-size: 14px;
    font-weight: 500;
}

.version-history-modal .drawer-footer {
    box-shadow: 0px -8px 16px 0px #0000000F;
}

.version-history-table-row {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 44% 43% 10%;
}

.version-histry-date {
    font-size: 14px;
    font-weight: 500;
}

.version-history-name b {
    width: 100%;
}

.version-history-name {
    color: #07142D;
    font-size: 14px;
    font-weight: 500;
}

.mark-comp-popup-cust-size .modal-text-info {
    margin-top: 5px;
    font-size: 14px;
    line-height: 20px;
}

.uploadDocumentPopup-subtitle {
    font-size: 14px;
    font-weight: 500;
}

.emailbox-input .select__dropdown-indicator {
    display: none;
}

.email-list-placeholder {
    color: #687281;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    place-items: center;
}

.placeholder-add-sign {
    color: #687281;
    font-size: 22px;
    font-weight: 300;
    margin-top: -2px;
    margin-right: 5px;
}

.sticky-slected-rows {
    background-color: #586EF2;
    place-items: center;
}

.sticky-slected-rows .lm-button2 {
    padding: 4px 12px;
}

.sticky-slected-rows p {
    font-size: 13px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 0;
}

.e-Signature-title {
    font-size: 16px;
    font-weight: 500;
}

.e-Signature-title img {
    margin-right: 10px;
    margin-top: -4px;
}

.doc-progress {
    display: flex;
}

.doc-progress svg {
    position: relative;
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
    margin: 1px 5px;
}

.doc-progress svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #4057E3;
    stroke-width: 3;
    stroke-linecap: round;
}

.doc-progress svg circle:last-of-type {
    stroke-dasharray: 625px;
    stroke-dashoffset: calc(625px - (625px * (50)) / 100);
    stroke: #3498db;
}

.doc-progress span {
    font-size: 14px;
    font-weight: 500;
    color: #99A4B1;
}

.doc-preview-box {
    border: 1px solid #EAEDF0;
    background-color: #F7F7F7;
    border-radius: 4px;
    padding: 25px;
}

.e-signature-drawer .lm-card {
    padding: 16px;
}

.e-signature-drawer .disabled-btn {
    background: #F3F4F6;
    color: #99A4B1;
    /* pointer-events: none; */
}

.e-signature-drawer .lm-nav-tabs {
    border-bottom: unset;
    width: 65%;
}

.e-signature-drawer .lm-nav-tabs .nav-tabs .nav-link {
    position: relative;
    padding: 0px 0 10px 15px;
}

.e-signature-drawer .lm-nav-tabs .nav-tabs .nav-link:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 8px;
    height: 7px;
    width: 7px;
    border-radius: 7px;
    background-color: #12B76A;
}

.e-signature-drawer .lm-nav-tabs .nav-tabs .nav-link.active:before {
    background-color: #FD151B;
}

.note-box {
    border: 1px solid #EAEDF0;
    background-color: #F7F7F7;
    padding: 16px 24px;
    border-radius: 6px;
    margin-bottom: 25px;
}

.note-box img {
    height: max-content;
    margin-right: 10px;
}

.note-box p {
    font-size: 13px;
    font-weight: 600;
    color: #07142D;
}

.e-signature-drawer~.modal-backdrop {
    z-index: 1050;
}

.e-signature-drawer .drawer-footer {
    box-shadow: 0px -8px 16px 0px #0000000F;
    width: 100vw;
    left: 0;
}

.esign-category-tab-sec {
    border: 1px solid #EAEDF0;
    border-radius: 6px;
    padding: 4px;
    display: flex;
    width: max-content;
    gap: 6px;
}

.esign-category-tab {
    border-radius: 4px;
    padding: 6px 16px;
    color: #99A4B1;
    font-size: 14px;
    display: flex;
    transition: 0.3s;
}

.esign-category-tab:hover {
    background-color: #F7F7F7;
    transition: 0.3s;
    cursor: pointer;
}

.current-tab {
    color: #FFFFFF;
    background-color: #4057E3;
}

.current-tab:hover {
    color: #FFFFFF;
    background-color: #4057E3;
}

.esign-category-tab i {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.mark-complete-icon {
    background-image: url('../../assets/icons/mark_com_icon.svg');
    background-size: contain;
    background-position: center;
    transition: 0.3s;
}

.current-tab .mark-complete-icon {
    background-image: url('../../assets/icons/mark_com_icon_white.svg');
    transition: 0.3s;
}

.date-block-icon {
    background-image: url('../../assets/icons/date_block_icon.svg');
    background-size: contain;
    background-position: center;
    transition: 0.3s;
}

.current-tab .date-block-icon {
    background-image: url('../../assets/icons/date_block_icon_white.svg');
    transition: 0.3s;
}


.doc_option_dots {
    font-size: 22px;
    line-height: 0px;
    color: #99A4B1;
    background: unset;
    border: unset;
    padding: 0;
    height: 0;
}

.doc_option_dots:hover,
.dropdown .doc_option_dots.show,
.dropdown .doc_option_dots:focus {
    color: #07142D;
    cursor: pointer;
    background-color: unset;
    border-color: unset;
    box-shadow: unset !important;
}

.doc_option_dots::after {
    display: none;
}

.doc_app_options {
    width: max-content;
    padding: 8px;
    box-shadow: 2px 2px 32px 0px #2E373724;
    border-radius: 6px;
    border: unset;
    background-color: #fff;
    position: absolute;
    bottom: auto;
    left: auto;
    right: 100%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 12;
    transition: opacity 0.3s, visibility 0.3s;
    margin-right: 10px;
}

/* 
.doc_app_options::after {
    content: ' ';
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-left: 12px solid #FFFFFF;
    border-bottom: 12px solid transparent;
    position: absolute;
    bottom: auto;
    left: auto;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin-right: -10px;
} */

.doc_app_options div {
    padding: 6px 10px;
    transition: 0.3s;
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 500;
}

.doc_app_options div:hover img {
    filter: brightness(0);
    transition: 0.3s;
}

.doc_app_options div:hover {
    cursor: pointer;
    background-color: #F3F4F6;
    border-radius: 6px;
    transition: 0.3s;
}

.doc_app_options hr {
    margin: 8px -8px;
    border-top: 1px solid #EAEDF0;
}

.doc_checklist_dropdown {
    padding: 8px;
    box-shadow: 2px 2px 32px 0px #2E373724;
    border-radius: 6px;
    border: unset;
    background-color: #fff;
    margin-right: -85px;
    margin-top: 8px;
    z-index: 99;
}

.doc_checklist_dropdown::before {
    content: ' ';
    height: 15px;
    width: 15px;
    transform: rotate(45deg);
    position: absolute;
    top: -4px;
    left: 50%;
    background-color: #ffffff;
}

.doc_checklist_dropdown div {
    padding: 6px 10px;
    transition: 0.3s;
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 500;
    min-width: max-content;
    width: 100%;
}

.doc_checklist_dropdown div:hover img {
    filter: brightness(0);
    transition: 0.3s;
}

.doc_checklist_dropdown div:hover {
    cursor: pointer;
    background-color: #F3F4F6;
    border-radius: 6px;
    transition: 0.3s;
}

.note-list .note-item {
    border-bottom: 1px solid #EAEDF0;
    margin-bottom: 18px;
}

.note-list .note-item:last-child {
    border-bottom: unset;
}

.note-list .note-item .note-text {
    font-size: 14px;
    font-weight: 500;
    color: #07142D;
    margin: 14px 0 18px;
}

.note-list .note-item .note-user {
    display: flex;
    place-items: center;
}

.note-list .note-item .note-user img {
    height: 24px;
    width: 24px;
    border-radius: 24px;
    margin-right: 8px;
}

.note-list .note-item .note-user h4 {
    font-size: 14px;
    font-weight: 700;
    color: #07142D;
}

@media (min-width:1025px) {
    .e-signature-drawer .drawer-footer {
        width: calc(100vw - 265px);
        left: 0;
    }
}


@media (min-width:575px) {
    .e-signature-drawer .modal-dialog {
        margin: 0;
        position: fixed;
        left: 0;
        margin-left: 265px;
        width: calc(100vw - 265px);
    }

    .e-signature-drawer .modal-content {
        width: calc(100vw - 265px);
    }
}

@media (min-width:575px) and (max-width:991px) {
    .e-signature-drawer .modal-dialog {
        left: 0;
        margin-left: 0px;
        width: 100vw;
    }

    .e-signature-drawer .modal-content {
        width: 100vw;
    }
}

@media (min-width: 575px) {
    .mark-comp-popup-cust-size .modal-dialog {
        width: 420px;
    }
}


@media (max-width: 575px) {
    .fst-col-25 .input-dollar {
        width: 200px;
    }

    .fst-col-15 .input-dollar {
        width: 200px;
    }

    .note-card .user-image .profile-img img {
        height: 35px;
        width: 35px;
    }

    .note-card .user-image .profile-text {
        height: 35px;
        width: 35px;
    }

    .note-card {
        padding: 16px;
    }

    .note-reply-card {
        padding: 16px;
    }

    .note-reply-sec {
        margin-top: 20px;
        margin-left: 30px;
    }
}

@media (min-width: 992px) {

    /* targeting large desktops */
    .pfs-card .col-lg-fifth {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .pfs-card .col-lg-fifth-double {
        flex: 0 0 40%;
        max-width: 40%;
    }
}

@media (min-width:575px) and (max-width:991px) {
    .application-detail .header-btn-sec {
        display: block;
    }

    .application-detail .header-btns {
        gap: 12px;
        margin-top: 20px;
        flex-wrap: wrap;
    }
}

@media (max-width: 575px) {

    .application-detail .appliaction-no {
        font-size: 18px;
    }

    .appliaction-header .lm-button2,
    .appliaction-header .lm-button1 {
        padding: 7px 10px;
        font-size: 13px;
    }

    .application-detail .lm-button2 img,
    .application-detail .lm-button2 svg {
        width: 16px;
        margin-right: 4px;
    }

    .application-detail .credit-memo-tabs {
        margin: -10px -24px 0px -24px;
    }

    .documet-wrap .grid-head {
        padding: 16px;
    }

    .application-detail .header-btn-sec {
        display: block !important;
    }

    .header-btns {
        gap: 12px;
        margin-top: 20px;
        flex-wrap: wrap;
    }

    .pfs-detail {
        overflow: hidden;
    }

    .pfs-card .lm-button3 {
        display: block;
    }

    .pfs-card .lm-button3 span {
        display: inline-block;
    }

    .line-breaker .label {
        width: 90%;
    }

    /* .documet-wrap .filter-search-group {
        display: block !important;
    } */

    .documet-wrap .filter-search-group .select__control {
        width: unset;
    }

    .documet-wrap .filter-search-group .lm-search-bar input {
        width: 100%;
    }

    .credit-memo-data-table .pfs-card-value {
        min-width: 300px;
        text-wrap-mode: wrap;
    }

}

.addition-feild label {
    display: none;
}

.Banks_Exposure_Review_card .row:last-child .lm-divider {
    display: none;
}

.CreditMemoActive {
    box-shadow: none !important;
}

.credit-memo-tabs {
    padding: 10px 24px;
    margin: -24px -24px 0px -24px;
    position: relative;
}

.credit-memo-tabs.radio-tabs .nav-item {
    width: max-content;
}

.credit-memo-tabs.radio-tabs .nav-link {
    padding: 5px 18px;
}

.credit-memo-tabs.radio-tabs .nav-tabs {
    width: max-content;
}

.memo-lm-list {
    margin-top: 3px;
}

.memo-lm-list li {
    font-size: 12px;
    font-weight: 500;
    color: #07142D;
    position: relative;
    padding-left: 12px;
}

.memo-lm-list li::before {
    content: ' ';
    position: absolute;
    width: 5px;
    height: 5px;
    left: 0;
    top: 6px;
    background: #05D3B8;
}

.red-list.memo-lm-list li {
    font-size: 13px;
}

.red-list.memo-lm-list li::before {
    background: #FD151B;
}

.green-list.memo-lm-list li {
    font-size: 13px;
}

.green-list.memo-lm-list li::before {
    background: #12B76A;
}

.memo-lm-list li span {
    color: #99A4B1;
    margin-right: 4px;
}

.current-sba-count {
    background: #EDECFF;
    color: #485664;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 14px;
    border-radius: 8px;
    height: 36px;
}