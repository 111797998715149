/* FadeOutUp - for when you toggle the class off, ensure smooth transition */
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.website-header .logo img{
    margin-top: -4px;
}

.website-header .link-secondary {
    position: relative;
    place-self: center;
    height: max-content;
    margin-right: 40px;
}

.website-header {
    font-family: "Manrope", sans-serif;
    background-color: #fff;
    /* box-shadow: 0px 24px 28px 0px #17226A0F; */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    padding: 0px 0;
}

.website-header .container {
    padding: 24px 84px;
    max-width: 1440px;
}

.menu-list {
    place-self: center;
    display: flex;
}

.menu-list .menu-item {
    padding: 20px 0;
}

.login-btn-link {
    color: #07142D;
    font-size: 16px;
    font-weight: 500;
}

.menu-item {
    margin: 0 16px;
    color: #07142D;
    transition: 0.3s;
    position: relative;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.16px;
}

.menu-item:hover {
    color: #4057E3;
    transition: 0.3s;
}


.website-header .link-secondary::after {
    content: ' ';
    width: 100%;
    height: 1px;
    background-color: #07142D;
    position: absolute;
    bottom: -2px;
    left: 0;
    transition: 0.3s;
}

.dropdown img {
    margin-top: -3px;
}

.btn-group {
    place-self: center;
}

.btn-register {
    padding: 10px 24px;
    border-radius: 4px;
    background: #4057E3;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    transition: 0.3s;
    font-family: "Manrope", sans-serif;
}

.btn-register:hover {
    background: #586EF2;
    color: #fff;
    transition: 0.3s;
}

.service-mega-menu {
    position: fixed;
    top: 75px;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    display: none;
    background-color: #FFFFFF;
    box-shadow: 0px 24px 28px 0px #17226A0F;
    opacity: 0;
    transition: 0.5s;
    z-index: 99;
}

.service_btn:hover+.service-mega-menu {
    display: block;
    animation: fadeInDown 0.5s both;
}

.service_btn:hover .service-mega-menu {
    display: block;
    animation: fadeInDown 0.5s both;
}

.service_btn:hover .service-mega-menu:not(:hover) {
    transition: 0.5s;
}

.service-mega-menu .container {
    display: flex;
}

.serivce_mm_show {
    display: block;
    animation: fadeInDown 0.5s both;
}

.ser-mega-menu-hero {
    background: linear-gradient(90deg, #22315D 0%, #918CF2 100%);
    width: 18%;
    margin-right: 20px;
    padding: 0 25px 25px;
}

.ser-mega-menu-hero img {
    margin-bottom: 40px;
}

.ser-mega-menu-hero h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #FFFFFF;
    font-family: "Sora", sans-serif;
}

.ser-mega-menu-hero a {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    position: relative;
}

.ser-mega-menu-hero a::after {
    content: ' ';
    width: 100%;
    height: 1px;
    background-color: #FFFFFF;
    position: absolute;
    bottom: -4px;
    left: 0;
}

.ser-menu-item-grp {
    width: 81%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.ser-menu-item {
    width: 23.8%;
    border: 1px solid #EFEFF4;
    padding: 13px 16px;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    color: #07142D;
    transition: 0.3s;
    position: relative;
}

.ser-menu-item:hover {
    transition: 0.3s;
    color: #4057E3;
    background-color: #f8f8f8;
}

.ser-menu-item::after {
    content: ' ';
    height: 20px;
    width: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    background-image: url('../../../src/assets/img/arrow_right.svg');
}

.ser-menu-item img {
    margin-top: -2px;
    margin-right: 10px;
}

.res-mega-menu {
    position: fixed;
    top: 75px;
    left: 0;
    width: 100%;
    display: none;
    background-color: #FFFFFF;
    box-shadow: 0px 24px 28px 0px #17226A0F;
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
    z-index: 99;
}

.res_mm_show {
    display: block;
    animation: fadeInDown 0.5s both;
}

.res_btn:hover .res-mega-menu {
    display: block;
    animation: fadeInDown 0.5s both;
}

.res-menu-item-grp {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
}

.res-menu-item {
    width: 19%;
    border: 1px solid #EFEFF4;
    padding: 13px 16px;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    color: #07142D;
    transition: 0.3s;
    position: relative;
}

.res-menu-item:hover {
    transition: 0.3s;
    color: #4057E3;
    background-color: #f8f8f8;
}

.res-menu-item::after {
    content: ' ';
    height: 20px;
    width: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    background-image: url('../../../src/assets/img/arrow_right.svg');
}

.res-menu-item img {
    margin-top: -2px;
    margin-right: 10px;
}

.website-header .hamburger-menu {
    display: block;
    cursor: pointer;
    font-size: 24px;
    margin-left: auto;
    transition: all .3s;
    place-content: center;
}

.website-header .bar {
    width: 20px;
    height: 2px;
    background: #07142D;
    margin: 4px 0;
    transition: all 0.3s ease;
}

.website-header .hamburger-menu:hover {
    transform: scale(1.1);
    transition: all .3s;
}

.website-header .active-hamburger .bar:nth-of-type(1) {
    transform: translate(0, 10px) rotate(-45deg);
}

.website-header .active-hamburger .bar:nth-of-type(2) {
    opacity: 0;
}

.website-header .active-hamburger .bar:nth-of-type(3) {
    transform: translate(0, -2px) rotate(45deg);
}

.slideRightIn {
    transform: translateX(0);
}

.slideLeftOut {
    transform: translateX(-100%);
}


.slide-menu .menu-item {
    border-bottom: 1px solid #EFEFF4;
    position: relative;
}

.slide-menu .menu-item a {
    transition: 0.3s;
    color: #07142D;
}

.slide-menu .menu-item:hover a {
    transition: 0.3s;
    color: #4057E3;
}

.slide-menu {
    transition: transform 0.3s ease;
    position: fixed;
    top: 72px;
    background-color: #FFFFFF;
    z-index: 999;
    width: 100%;
}

.slide-menu-scroll {
    min-height: calc(100vh - 74px);
    overflow: auto;
}

.slide-menu .service-mega-menu .container {
    display: block;
}

.slide-menu .megamenu-dropdown {
    transition: 0.5s;
    filter: brightness(0%);
}

.slide-menu .active-menu-item .megamenu-dropdown {
    transition: 0.5s;
    transform: rotate(180deg);
    filter: unset;
}

.slide-menu .menu-item:hover{
    color: #07142D;
}

.slide-menu .active-menu-item {
    color: #4057E3 !important;
}

.slide-menu .service-mega-menu .container,
.slide-menu .res-mega-menu .container{
    padding: 0;
}

.slide-menu .ser-mega-menu-hero a:hover, .slide-menu .ser-mega-menu-hero a  {
    color: #FFFFFF !important;
}

.slide-menu .ser-mega-menu-hero{
    padding: 16px 24px 20px;
}

.slide-menu .service-mega-menu,
.slide-menu .res-mega-menu {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    box-shadow: unset;
    opacity: 1;
    animation: none;
}

@media (min-width: 575px) and (max-width: 1024px) {
    .website-header .container {
        padding: 15px 15px;
    }

    .ser-menu-item {
        width: 47.8%;
    }

    .res-menu-item {
        width: 31%;
    }

    .menu-item {
        margin: 0 10px;
    }

    .website-header .link-secondary {
        margin-right: 20px;
        font-size: 14px;
    }

    .btn-register {
        padding: 4px 10px 7px 10px;
        font-size: 14px;
        font-weight: 600;
    }
}

@media (min-width:575px) {
    .menu-icon,
    .close_menu_icon {
        display: none;
    }
}

@media (max-width:991px) {
    .website-header{
        padding: 0 5px;
    }
    .website-header .logo img{
        margin-top: 1px;
    }
    .website-header .hamburger-menu{
        margin-top: 4px;
    }
    .slide-menu .menu-item .megamenu-dropdown{
        position: absolute;
        right: 0;
    }
    .slide-menu .menu-item{
        padding-bottom: 12px !important;
        padding-top: 13px !important;
    }
    .slide-menu .login-btn-link{
        border-bottom: unset;
    }
    .slide-menu .btn-group{
        margin: 0 20px;
    }
    .btn-register {
        display: block;
        padding: 10px 24px;
        font-size: 14px;
        font-weight: 600;
    }
    .login-btn-link::after {
        content: ' ';
        width: 100%;
        height: 1px;
        background-color: #07142D;
        position: absolute;
        bottom: 9px;
        left: 0;
        transition: 0.3s;
    }
    .slide-menu .service-mega-menu, .slide-menu .res-menu-item-grp {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .slide-menu .ser-menu-item, .slide-menu .res-menu-item {
        border: unset;
        padding: 3px 0px;
        color: #07142D !important;
    }
    .slide-menu .ser-menu-item::after, .slide-menu .res-menu-item::after {
        top: 5px;
        right: 0px;
    }
}


@media (min-width:1024px) {
    .menu-item .megamenu-dropdown {
        transition: 0.5s;
        filter: brightness(0);
        margin-left: 3px;
    }

    .menu-item:hover .megamenu-dropdown {
        transition: 0.5s;
        transform: rotate(180deg);
        filter: unset;
    }
}

@media (min-width:921px){
    .lm-logo-for-mobile-only{
        display: none;
    }
    .mobile-only-link{
        display: none;
    }
}

.lm-logo-for-mobile-only img{
    width: 125px;
}

@media (max-width:921px) {
    .website-header{
        display: none;
    }

    .website-main .website-wrap{
        padding-top: 0;
    }

    .website-header .container {
        padding: 15px 15px;
    }

    .website-header .service-mega-menu .container {
        display: block;
    }

    .menu-list .menu-item {
        padding: 0;
    }

    .ser-mega-menu-hero {
        width: 100%;
        margin-bottom: 20px;
    }

    .res-menu-item {
        width: 100%;
    }

    .ser-menu-item-grp {
        width: 100%;
    }

    .ser-menu-item {
        width: 100%;
    }

    .menu-icon {
        margin-left: 15px;
    }

    .service-mega-menu {
        box-shadow: none;
    }

    .side-menu-mobile .menu-item {
        width: 100%;
    }

    .website-header .link-secondary {
        margin-right: 20px;
        font-size: 16px;
    }
}