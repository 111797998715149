.users-screen .filter-search-group {
    place-items: center;
}

.users-screen .user-page-heading {
    font-size: 18px;
    font-weight: 700;
}

.users-screen .lm-grid-table td {
    padding: 14px 16px;
}

.users-screen .lm-select {
    width: 220px;
}

.users-screen .lm-search-bar input {
    width: 360px;
}

.users-screen .tel_number {
    margin-bottom: 5px;
}

.users-screen .tel_number img {
    margin-right: 8px;
}

.users-screen .user_email {
    font-size: 12px;
    font-weight: 500;
}

.user_name {
    font-size: 14px;
    font-weight: 700;
}

.users-screen .advisor,
.users-screen .partner,
.users-screen .lender,
.users-screen .add_partner {
    text-decoration: underline;
}

.status {
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    width: max-content;
}

.new .status,
.active .status,
.status.new,
.status.active {
    background: #DAF4E8;
    color: #12B76A;
}

.closed .status,
.inactive .status,
.status.closed,
.status.inactive {
    background: #FFE4E4;
    color: #FD151B;
}


.status.completed,
.completed .status {
    background: #EAEDF0;
    color: #344152;
}


.in-progress .status,
.inactive .status,
.status.in-progress,
.status.inactive {
    background: #FFE9DC;
    color: #EE7930;
}

.status .in-progress {
    background: #FFE9DC;
    color: #EE7930;
}

.on-hold .status,
.inactive .status,
.status.on-hold,
.status.inactive {
    background: #FFF5C5;
    color: #C49418;
}

.status .on-hold {
    background: #FFF5C5;
    color: #C49418;
}

.document-required .status,
.inactive .status,
.status.document-required,
.status.inactive {
    background: #EEF4FF;
    color: #055FFC;
}

.status .document-required {
    background: #EEF4FF;
    color: #055FFC;
}

.review-required .status,
.inactive .status,
.status.review-required,
.status.inactive {
    background: #F4EFFF;
    color: #6941C6;
}

.status .review-required {
    background: #F4EFFF;
    color: #6941C6;
}


.loan_amount {
    text-align: right;
}

.users-screen .option_dots {
    font-size: 22px;
    line-height: 12px;
    color: #99A4B1;
}

.users-screen .option_dots:hover {
    color: #07142D;
    cursor: pointer;
}

.users-screen .options_btn {
    position: relative;
}

.users-screen .app_options {
    opacity: 0;
    visibility: hidden;
    width: max-content;
    padding: 8px;
    box-shadow: 2px 2px 32px 0px #2E373724;
    border-radius: 6px;
    background-color: #fff;
    position: absolute;
    bottom: auto;
    left: auto;
    right: 100%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
    transition: opacity 0.6s, visibility 0.6s;
    margin-right: 10px;
}

.users-screen .options_btn:hover .app_options {
    opacity: 1;
    visibility: visible;
}


.users-screen .app_options::after {
    content: ' ';
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-left: 12px solid #FFFFFF;
    border-bottom: 12px solid transparent;
    position: absolute;
    bottom: auto;
    left: auto;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin-right: -10px;
}

.users-screen .app_options div {
    padding: 6px 10px;
}

.app_options div:hover {
    cursor: pointer;
    background-color: #F3F4F6;
    border-radius: 6px;
}

.users-screen .lm-tooltip img {
    width: 13px;
    margin-left: 5px;
}

.users-screen .last-login-box {
    border: 1px solid #EAEDF0;
    background-color: #F0F2FF;
    border-radius: 6px;
    padding: 8px 24px 10px;
    margin-top: 12px;
}

.users-screen .last-login-box span {
    color: #0F1828;
    font-size: 14px;
    font-weight: 500;
}

.transactions-drawer .drawer-body {
    padding: 72px 0px;
}

.transactions-drawer .drawer-head span,
.requirements-drawer .drawer-head span {
    font-size: 14px;
}

.transactions-drawer .user-transition {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EAEDF0;
    padding: 16px 24px;
}

.requirements-drawer .lm-nav-tabs {
    border-bottom: 1px solid #EAEDF0;
}

.requirements-drawer .drawer-footer,
.e-signature-drawer .drawer-footer {
    width: 100vw;
    left: 0;
}

.requirements-form-sec .sticky-btn-footer {
    width: calc(100vw - 265px);
    left: 0;
    margin-left: 0;
}

.requirements-drawer .loader-box{
    left: 110%;
    width: max-content;
}

.user-dtls-count-box {
    border: 1px solid #EAEDF0;
    padding: 8px 16px;
    border-radius: 4px;
}

.user-dtls-count-box p {
    font-size: 12px;
    text-transform: uppercase;
    color: #97A3B2;
    font-weight: 600;
    margin-bottom: 0;
}

.user-dtls-count-box p b {
    font-size: 12px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 0;
    margin-left: 6px;
}

.user-rating-content .user-applicaion-dtls .appliaction-no {
    color: #22315D;
    font-size: 24px;
    font-weight: 800;
    margin-right: 15px;
}

.user-rating-content .user-applicaion-dtls small {
    font-size: 12px;
    font-weight: 500;
    color: #97A3B2;
}

.user-applicaion-dtls-accordian-button {
    position: relative;
    font-size: 14px;
    font-weight: 500;
}

.user-applicaion-dtls-accordian-button::after {
    content: ' ';
    height: 18px;
    width: 18px;
    background-image: url('../../assets/img/angle_down.svg');
    position: absolute;
    right: 10px;
    top: 4px;
    transition: 0.3s;
}

.open-user-applicaion-dtls-accordian::after {
    transform: rotate(180deg);
    transition: 0.3s;
}

.user-applicaion-dtls-accordian .label {
    font-size: 13px;
    font-weight: 600;
    color: #97A3B2;
    margin-bottom: 0;
}

.user-applicaion-dtls-accordian .value {
    font-size: 13px;
    font-weight: 600;
    color: #07142D;
}

.user-rating-content .select_feedback .option {
    border: 1px solid #D1D5DC;
    border-radius: 6px;
    padding: 6px 10px;
    font-size: 13px;
    font-weight: 600;
    color: #97A3B2;
    cursor: pointer;
}

.user-rating-content .select_feedback .option.if_selected {
    background: #71719C;
    color: #ffffff;
}

.user-rating-content .rating-log-table tbody .comment-box-row {
    background-color: #ffffff;
}

.user-rating-content .rating-log-table tbody tr.open-comment {
    background-color: #fff;
}

.user-rating-content .rating-log-table tbody tr {
    background-color: #F7F7F7;
}

.user-rating-content .rating-log-table tr:hover {
    background-color: #F7F7F7;
    box-shadow: 0px 2px 8px 0px #00000000;
    transition: 0.5s;
}

.user-rating-content .rating-log-table tbody tr.open-comment {
    background-color: #fff;
}

.user-rating-content .rating-log-table tbody .comment-box-row:hover {
    background-color: #ffffff;
}

.user-rating-content .rating-log-table tr th {
    padding: 18px 16px;
}

.lender-user-name{
    border-top: 1px solid #EAEDF0;
    background-color: #f7f7f7;
    border-radius: 24px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    width: max-content;
    place-content: center;
    padding: 5px 10px;
}

.lender-user-name img{
    margin-right: 5px;
}

.comment-box-row p {
    border: 1px solid #EAEDF0;
    border-radius: 24px;
    padding: 5px 14px;
    font-size: 13px;
    font-weight: 500;
    color: #687281;
}

.openComment_btn {
    border: unset;
    background-color: transparent;
    padding: 0;
    color: #4057E3;
    text-decoration: underline;
    position: relative;
    padding-right: 20px;
}

.openComment_btn::after {
    content: ' ';
    height: 16px;
    width: 16px;
    background-size: contain;
    background-image: url('../../assets/img/angle_down.svg');
    position: absolute;
    right: 0px;
    top: 2px;
    transition: 0.3s;
}

.rating-log-table tbody tr.open-comment .openComment_btn::after {
    transform: rotate(180deg);
    transition: 0.3s;
}

.sendEmailPopup.modal,
.rating_popup.modal{
    z-index: 1040;
}

@media (max-width:1024px) {
    .requirements-form-sec .sticky-btn-footer {
        width: 100%;
    }
}

@media (min-width:1025px) {

    .requirements-drawer .drawer-footer,
    .e-signature-drawer .drawer-footer {
        width: calc(100vw - 265px);
        left: 0;
    }
}


@media (min-width:575px) {

    .requirements-drawer .modal-dialog,
    .e-signature-drawer .modal-dialog {
        margin: 0;
        position: fixed;
        left: 0;
        margin-left: 265px;
        width: calc(100vw - 265px);
    }

    .requirements-drawer .modal-content,
    .e-signature-drawer .modal-content {
        width: calc(100vw - 265px);
    }
}

@media (min-width:575px) and (max-width:991px) {

    .requirements-drawer .modal-dialog,
    .e-signature-drawer .modal-dialog {
        left: 0;
        margin-left: 0px;
        width: 100vw;
    }

    .requirements-drawer .modal-content,
    .e-signature-drawer .modal-content {
        width: 100vw;
    }
}



@media (max-width:575px) {

    /* .users-screen .filter-search-group,
    .search-button-group {
        display: block !important;
    } */

    .user-page-heading,
    .lm-search-bar {
        margin-bottom: 15px;
    }

    .users-screen .filter-search-group .lm-search-bar input {
        width: 100%;
    }

    .users-screen .lm-search-bar input {
        width: 100%;
    }

    .users-screen .lm-button1{
        white-space: nowrap;
        width: max-content;
    }

    .rated-user-dtls .rated-user-dtls-row{
        flex-wrap: wrap;
    }

    .rated-user-dtls .lm-button2{
        white-space: nowrap;
    }

    .user-rating-content .user-applicaion-dtls .appliaction-no {
        font-size: 20px;
    }

    .lender-user-name {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .lender-user-name img {
        width: 16px;
    }

    .user-applicaion-dtls-accordian .col-md-3{
        margin-bottom: 10px;
    }

    .rating_popup .loader-box {
        left: 22%;
    }

}